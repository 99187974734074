import React from "react"
import { Link } from "gatsby"
import ButtonText from "../../elements/buttonText/ButtonText"
import { Styled404, ContainerTitle, Title404, SubTitle404, Stage404, StageContent } from "./404.styles"

const Page404 = ({ title }) => {
  const backBtn = "button_volver"

  return (
    <Styled404 className={`o-page-404${title ? `_${title.split("").join("_")}` : ""}`}>
      <Stage404>
        <StageContent>
          <ContainerTitle>
            <Title404>404</Title404>
            <div className="ContainerInfoTitle">
              <SubTitle404>Mmmm…. creo que no es por aquí</SubTitle404>
              <div className="text_description">
                <p>Parece que llegamos a donde no era. ¡Volvamos a la ruta para seguir explorando!</p>
              </div>
            </div>
          </ContainerTitle>
          <div className="container_buttom">
            <Link to="/">
              <ButtonText className={backBtn}>Explora más historias</ButtonText>
            </Link>
          </div>
        </StageContent>
      </Stage404>
    </Styled404>
  )
}

export default Page404
