import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"
import background320 from "../../../../static/assets/images/404/error404_320.jpg"
import background768 from "../../../../static/assets/images/404/error404_768.jpg"
import background1024 from "../../../../static/assets/images/404/error404_1024.jpg"
import background1366 from "../../../../static/assets/images/404/error404_1366.jpg"

export const Styled404 = styled.section`
  width: 100%;
  /* overflow: hidden; */
  /* height: 100vh; */
  background-image: url(${background320});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  padding-bottom: 10px;
  @media ${device.sm} {
    background-image: url(${background768});
    /* height: 100vh; */
  }
  @media ${device.md} {
    background-image: url(${background1024});
    height: 100%;
    padding-bottom: 0;
  }
  @media ${device.lg} {
    background-image: url(${background1366});
  }

  .container_buttom {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    position: relative;

    @media ${device.sm} {
      margin-top: 300px;
      margin-bottom: 90px;
    }
    @media ${device.md} {
      margin-top: 60px;
      margin-bottom: 0;
    }
    @media ${device.md} {
      margin-top: 70px;
    }
    @media ${device.xl} {
      margin-top: 10px;
    }
  }

  .button_volver {
    background-color: ${props => props.theme.vars.goldenTainoi};
    font-family: ${props => props.theme.vars.tradeGot};
    font-size: 14px;
    font-weight: ${props => props.theme.vars.fontBold};
    letter-spacing: 1px;
    border: none;
    width: 252px;
    height: 70px;
    color: ${props => props.theme.vars.black};
  }
  .button_volver:hover {
    cursor: pointer;
    background-color: ${props => props.theme.vars.outerSpace};
    color: ${props => props.theme.vars.white};
  }
`

export const Stage404 = styled.div`
  @media ${device.md} {
    height: calc(100vh - 80px);
    min-height: 652px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media ${device.lg} {
    min-height: 614px;
  }
`
export const StageContent = styled.div``

export const ContainerTitle = styled.div`
  width: 100%;
  border: solid transparent 1px;
  position: relative;
  text-align: center;

  .ContainerInfoTitle {
    margin-top: 200px;

    @media ${device.sm} {
      margin-top: 15px;
    }
    @media ${device.md} {
      margin-top: 0px;
    }

    .text_description {
      color: ${props => props.theme.vars.white};
      line-height: 20px;
      font-weight: ${props => props.theme.vars.regular};
      width: 100%;
      text-align: center;
      padding: 0 40px;
      font-size: 14px;

      @media ${device.sm} {
        padding: 0 calc(25.5vw);
        font-size: 18px;
        line-height: 25px;
      }
      @media ${device.md} {
        padding: 0 calc(32vw);
        position: relative;
        margin-top: 30px;
      }

      @media ${device.lg} {
        padding: 0 calc(33.6vw);
      }
      @media ${device.xl} {
        padding: 0 calc(35vw);
        line-height: 35px;
        font-size: 24px;
        position: relative;
        top: -30px;
      }
    }
  }
`

export const Title404 = styled.h1`
  font-family: ${props => props.theme.vars.avenirNext};
  color: ${props => props.theme.vars.white};
  font-weight: ${props => props.theme.vars.fontBlack};
  font-size: calc(40vw);
  line-height: 0.91em;
  margin-top: 80px;

  @media ${device.sm} {
    font-size: calc(28.1vw);
    margin-top: calc(24vw);
  }
  @media ${device.md} {
    margin-top: 0;
    font-size: calc(25.2vw);
  }
  @media ${device.lg} {
    font-size: calc(18.9vw);
  }
  @media ${device.xl} {
    font-size: calc(19.38vw);
    margin-bottom: 80px;
    display: block;
    line-height: 0.9;
  }
`

export const SubTitle404 = styled.h1`
  font-family: ${props => props.theme.vars.avenirNext};
  color: ${props => props.theme.vars.white};
  font-weight: ${props => props.theme.vars.fontBlack};
  font-size: calc(7.2vw);
  line-height: 0.91em;
  margin-bottom: 15px;
  text-transform: uppercase;

  @media ${device.sm} {
    font-size: calc(4.9vw);
    margin-top: 0px;
  }
  @media ${device.md} {
    font-size: calc(3.75vw);
    /* margin-top: 30px;
    margin-bottom: 30px; */
    margin: 0;
  }

  @media ${device.lg} {
    font-size: calc(2.8vw);
    margin-top: 10px;
  }
  @media ${device.xl} {
    position: relative;
    top: -60px;
    font-size: 58px;
  }
`
